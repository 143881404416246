@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Open+Sans&display=swap");

#title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  color: #303133;
}

.addCartBtn {
  --background: #0083d0 !important;
  --color: white;
  --width: 100%;
  height: 30px;
}

.itemName {
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 15px;
}

.saleSize{
  width: 59px;
height: 75.5px;
position: absolute;
margin-left: 20px;
z-index: 1;
}

@media (min-width: 768px) {
  /* Medium screen size */
  .itemName {
    font-size: 20px;
    color: black;
    margin-bottom: 15px;
  }

}

@media (max-width: 767px) {
  .saleSize{
    width: 59px;
  height: 75.5px;
  position: absolute;
  margin-left: 10px;
  z-index: 1;
  }
}

.priceFont{
  color: #303133;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 300;
padding: 0px;
}

.priceFont2{
  color: #303133;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
padding: 0px;
text-align: center;
}

.scroll {
  display: flex; /* Make the buttons appear in a row */
  overflow-x: auto; /* Enable horizontal scrolling */
  -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
  scrollbar-width: none; /* Hide scrollbar on Firefox */
}

.scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Chrome and Safari */
}

.addCartBtn:hover {
  --background: #8eb2c8;
}

.menuBtn {
  --background: "blue";
}

.btnItems {
  --background: white;
  --box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
  --border-radius: 6px;
  --color: #0083d0;

  --font-family: "Inter";
  --font-style: normal;
  --font-weight: 500;
  --font-size: 17px;
  --line-height: 21px;
}

.btnItems:hover {
  --background: #0083d0;
  --box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
  --border-radius: 6px;
  --color: white;

  --font-family: "Inter";
  --font-style: normal;
  --font-weight: 500;
  --font-size: 17px;
  --line-height: 21px;
}

.active {
  --background: #0083d0;
}

.productName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  margin-top: 0px;
  color: #303133;
}

.productText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;

  color: #303133;
}

.inactive {
  --background: white;
}

.rowMenu {
  cursor: pointer;
  display: block;
  margin: auto;
  padding: 10px;
  width: 90%;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 18.6707px;
  line-height: 25px;
  color: #655e5f;
}

.rowMenu:hover {
  background: rgba(35, 168, 242, 0.71);
  color: white;
}

.rowMenu:active {
  background: rgba(35, 168, 242, 0.71);
  color: white;
}

.ion-padding {
  --background: #f4f6f8;
}

.toolbar {
  --background: #f4f6f8;
}
