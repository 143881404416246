@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");

.secondCol {
  background: linear-gradient(252.44deg, #0083d0 0%, #23a8f2 100%);
  height: 100vh;
}

.txtField {
  border: 0.913707px solid #bebebe;
  border-radius: 9.13707px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.txtLabel {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18.5443px;
  line-height: 32px;
  color: #000000;
}

.btnLogin {
  --background: linear-gradient(270deg, #0083d0 0%, #23a8f2 100%);
  --box-shadow: 0px 0px 7.5914px -2.84677px rgba(0, 0, 0, 0.17);
  --border-radius: 9.13707px;
  --color: white;
}

.txtLogin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 43.02px;
  line-height: 65px;
  /* identical to box height */

  margin-bottom: 30px;
  color: #000000;
}
