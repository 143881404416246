#title {
  font-style: normal;
  font-weight: 700;
  font-size: 20.2072px;
  line-height: 20px;
  color: #303133;
}


.menuBtn {
  --background: "blue";
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btnTransactions {
  --background: white;
  --box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
  --border-radius: 6px;
  --color: #003B5D;

  --font-family: "Inter";
  --font-style: normal;
  --font-weight: 500;
  --font-size: 17px;
  --line-height: 21px;
}

.btnTransactions:hover {
  --background: #0083d0;
  --box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.05);
  --border-radius: 6px;
  --color: white;

  --font-family: "Inter";
  --font-style: normal;
  --font-weight: 500;
  --font-size: 17px;
  --line-height: 21px;
}

.rowMenu {
  cursor: pointer;
  display: block;
  margin: auto;
  padding: 10px;
  width: 90%;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 18.6707px;
  line-height: 25px;
  color: #655e5f;
}

.rowMenu:hover {
  background: rgba(35, 168, 242, 0.71);
  color: white;
}

.rowMenu:active {
  background: rgba(35, 168, 242, 0.71);
  color: white;
}

.ion-padding {
  --background: #f4f6f8;
}

.toolbar {
  --background: #f4f6f8;
}

.transactionsTxt {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  color: #5b5b5b;
}

.contentSize{
  overflow-x: auto; 
  width: 100%; 
}

.tableStyles{
  width: 100%;
}

@media (max-width: 767px) {

.tableStyles{
  width: 700px;
}
}