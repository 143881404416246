@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");

#title {
  font-style: normal;
  font-weight: 700;
  font-size: 20.2072px;
  line-height: 20px;
  color: #303133;
  margin: auto;
}


.menuBtn {
  --background: "blue";
}


.rowMenu {
  cursor: pointer;
  display: block;
  margin: auto;
  padding: 10px;
  width: 90%;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 18.6707px;
  line-height: 25px;
  color: #655e5f;
}

.rowMenu:hover {
  background: rgba(35, 168, 242, 0.71);
  color: white;
}

.rowMenu:active {
  background: rgba(35, 168, 242, 0.71);
  color: white;
}

.ion-padding {
  --background: #f4f6f8;
}

.toolbar {
  --background: #f4f6f8;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #303133;
}

.btnOrder {
  --background: #0083d0;
  --color: white;
  --width: 100vw;
}
