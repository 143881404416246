@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.accBtn {
  --background: #0083d0;
  --color: white;
  --border-radius: 3px;
}

#accTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24.2072px;
  line-height: 47px;
  /* identical to box height */

  color: #303133;
}

.accName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22.906px;
  line-height: 35px;
}

.accEmail {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;

  color: #655e5f;
}

.label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;

  color: #655e5f;
}

.changePass{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */


color: #655E5F;
}